.about__container {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    column-gap: 4rem;
}

.about__img {
    width: 400px;
    border-radius: 1.5rem;
    justify-self: center;
    box-shadow: 0 0rem 0rem rgba(0, 0, 0.25, 0.25);
    transition: all 0.3s ease-in-out;
}

.about__img:hover {
    box-shadow: 0 1rem 1.5rem rgba(0, 0.25, 1, 0.25);
    transition: all 0.3s ease-in-out;
}

.about__info {
    grid-template-columns: repeat(3, 140px);
    gap: 0.5rem;
    margin-bottom: var(--mb-2);
    justify-content: center;
    padding: 0 2rem 0 0;
}

.about__data {
    text-align: center;
    justify-content: center;
}

.about__box {
    background-color: var(--container-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.75rem;
    text-align: center;
    padding: 1rem 1.25rem;
}

.about__icon {
    font-size: 1.5rem;
    color: var(--title-color);
    margin-bottom: var(--mb-0-5);
}

.about__title {
    font-size: var(--small-font-size);
    font-weight: var(--font-medium);
}

.about__subtitle {
    font-size: var(--tiny-font-size);
}

.about__description {
    padding: 0 1rem 0 0;
    margin-bottom: var(--mb-2-5);
    text-align: center;
}

/* Breakpoints */
/* For Large Devices */
@media screen and (max-width: 992px) {
    .about__container {
        grid-template-columns: 1fr;
        row-gap: 2.5rem;
    }

    .about__img {
        width: 220px;
    }

    .about__box {
        padding: 0.75rem 0.5rem;
    }

    .about__data {
        text-align: center;
        justify-content: center;
    }

    .about__info {
        justify-content: center;
        padding: 0 3rem 0 3rem;
    }

    .about__description {
        padding: 0 3rem;
        margin-bottom: 2rem; 
    }
}

/* For Medium Devices */
@media screen and (max-width: 576px) {
    .about__info {
        grid-template-columns: repeat(1, 1fr);
        padding: 0 3rem 0 3rem;
    }

    .about__description {
        padding: 0;
    }
}

/* For Small Devices */
@media screen and (max-width: 400px) {
    .about__info {
        grid-template-columns: repeat(1, 1fr);
        padding: 0 3rem 0 3rem;
    }
}