.qualification__container {
    max-width: 768px;
}

.qualification__tabs {
    display: flex;
    justify-content: center;
    margin-bottom: var(--mb-2);
}

.qualification__button {
    outline: 1px solid var(--text-color);
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    border-color: var(--text-color);
    background-color: var(--container-color);
    color: var(--text-color);
    margin: 0 var(--mb-1);
    cursor: pointer;
    padding: 0.1rem 1.25rem 0.1rem 1.25rem;
    border-radius: 0.5rem;
    box-shadow: 0 0rem 0rem rgba(0, 0, 0, 0.15);
    transition: all 0.3s ease-in-out;
    /* display: inline-block;
    background-color: var(--title-color);
    color: var(--container-color);
    padding: 1.25rem 2rem;
    border-radius: 1rem;
    font-weight: var(--font-medium); */
}

.qualification__button:hover {
    box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, 0.15);
    transition: all 0.3s ease-in-out;
}

.qualification__active {
    outline: 2px solid var(--text-color);
    border-color: var(--title-color-dark);
    border-radius: 0.5rem;
    color: var(--title-color-dark);
}

.qualification__icon {
    font-size: 1.8rem;
    margin-right: var(--mb-0-25);
}

.qualification__sections {
    display: grid;
    grid-template-columns: 0.76fr;
    justify-content: center;
}

.qualification__content {
    display: none;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

.qualification__content.fade-out {
    opacity: 0;
}

.qualification__content.fade-in {
    opacity: 1;
}


.qualification__content-active {
    display: block;
    transition: 3s;
    /* transition: all 1s ease-in-out; */

}

.qualification__data {
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    column-gap: 1.5rem;
}

.qualification__title {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    text-align: center;
    margin-bottom: var(--mb-0-25);
}

.qualification__title__dark {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    text-align: center;
    margin-bottom: var(--mb-0-25);
    color: var(--title-color-dark);
}

.qualification__subtitle {
    font-size: var(--small-font-size);
    text-align: center;
    margin-bottom: var(--mb-0-25);
    /* display: inline-block; */
    display: block;
    justify-content: center;
}

.qualification__calendar {
    font-size: var(--small-font-size);
    text-align: center;
}

.qualification__calendar-icon {
    margin-right: 0.5rem;
}

.qualification__rounder {
    display: inline-block;
    width: 13px;
    height: 13px;
    background-color: var(--text-color);
    border-radius: 50%;
    margin-top: 0.4rem;
}

.qualification__rounder__dark {
    display: inline-block;
    width: 13px;
    height: 13px;
    background-color: #000000;
    border-radius: 50%;
    margin-top: 0.4rem;
}

.qualification__line {
    display: block;
    width: 1px;
    height: 100%;
    background-color: var(--text-color);
    transform: translate(6px, -7px);
}

/* Breakpoints */
/* For Large Devices */
@media screen and (max-width: 992px) {
    .qualification__container {
        margin-left: auto;
        margin-right: auto;
    }
}

/* For Medium Devices */
@media screen and (max-width: 768px) {
    .qualification__container {
        margin-left: var(--mb-1-5);
        margin-right: var(--mb-1-5);
    }
}

@media screen and (max-width: 576px) {
    .qualification__sections {
        grid-template-columns: initial;
    }

    .qualification__button {
        margin: 0 var(--mb-0-75);
    }
}

/* For Small Devices */
@media screen and (max-width: 400px) {
    .qualification__data {
        gap: 0.5rem;
    }
}